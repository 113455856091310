.container {
  background-color: var(--env-block-background-color);
  border: 1px solid var(--env-block-border-color);
}

.container--loading {
  opacity: 0.2;
}

.text {
  color: var(--env-block-font-color);
  font-family: var(--env-font-family);
}
